<script lang="ts" setup>
import type { MoleculesFreeGiftProps } from '@design-system/components/Molecules/FreeGift/MoleculesFreeGift.props'

const props = withDefaults(defineProps<MoleculesFreeGiftProps>(), {
  productName: '',
  currentAmount: 0,
  targetAmount: 0,
  currency: 'EUR',
  ctaType: 'link',
  guest: true,
  imgHasBackground: false,
})
const { formatPriceByLocale } = useFormatPrice()

const emit = defineEmits<{
  onSignUp: []
  onLogin: []
  onSeeDetails: []
}>()

const amountLeft = computed(() => props.targetAmount - props.currentAmount)
const currentState = computed(() =>
  amountLeft.value > 0 ? 'progress' : 'eligible'
)
</script>

<template>
  <div class="free-gift flex gap-x-4 p-6">
    <NuxtPicture
      :class="[
        'w-30 aspect-[4/5] object-contain',
        {
          'bg-neutral-white': imgHasBackground,
        },
      ]"
      :src="img"
      provider="cloudinary"
      width="200"
      height="250"
      :placeholder="[67, 85]"
      :alt="productName"
      :modifiers="{ aspectRatio: '4:5' }"
      fit="fill"
      sizes="200px"
    />
    <div class="flex flex-1 flex-col">
      <div class="flex-1 space-y-6">
        <div class="space-y-1">
          <p v-if="currentState === 'eligible'" class="text-light-6">
            {{
              guest
                ? $ts('freeGift.valueReached')
                : $ts('freeGift.thanksToYourOrder')
            }}
          </p>
          <h6 class="text-medium-6 uppercase">
            {{
              currentState === 'eligible'
                ? productName
                : $ts('freeGift.giftForYou')
            }}
          </h6>
          <p v-if="!guest || currentState === 'progress'" class="text-light-6">
            <span v-if="description">{{ description }}</span>
            <span v-else>
              {{
                currentState === 'eligible'
                  ? $ts('freeGift.freeUntil')
                  : `${$ts('freeGift.receiveAGiftWhen')} ${formatPriceByLocale(currency, amountLeft)}`
              }}
            </span>
          </p>
        </div>
        <span
          v-if="amountLeft > 0"
          :style="{ '--progress': `${(currentAmount / targetAmount) * 100}%` }"
          class="bg-primitives-grey-100 before:bg-primitives-black block h-1 rounded-full before:block before:h-full before:w-[var(--progress)] before:rounded-full"
        />
      </div>
      <div v-if="guest" class="text-light-6">
        <AtomsCta anatomy="link" @click="emit('onSignUp')">
          <template #label>
            {{ $ts('freeGift.signUp') }}
          </template>
        </AtomsCta>
        <span>{{ ` ${$ts('freeGift.or')} ` }}</span>
        <AtomsCta anatomy="link" @click="emit('onLogin')">
          <template #label>
            {{ $ts('freeGift.login') }}
          </template>
        </AtomsCta>
        <span>
          {{ ` ${$ts('freeGift.enjoyTheBenefit')}` }}
        </span>
      </div>
      <MoleculesLinkWrapper
        v-else-if="ctaType === 'link' && !!to"
        anatomy="link"
        class="self-start"
        :to="to"
      >
        <template #label>
          {{ $ts('freeGift.seeDetails') }}
        </template>
      </MoleculesLinkWrapper>
      <AtomsCta
        v-else-if="ctaType === 'btn'"
        class="self-start"
        anatomy="link"
        @click="emit('onSeeDetails')"
      >
        <template #label>
          {{ $ts('freeGift.seeDetails') }}
        </template>
      </AtomsCta>
    </div>
  </div>
</template>
